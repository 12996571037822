import React, { useCallback, useEffect, useRef, useState } from 'react';

import mediator from 'Utilities/mediator';

import styles from './WizardMainContainer.module.scss';

const WizardMainContainer: React.FC<any> = ({ children }) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [prevScroll, setPrevScroll] = useState(0);

  const scrollTop = useCallback(() => {
    if (wrapper && wrapper.current) {
      wrapper.current.scrollTop = 0;
    }
  }, []);

  const onScrolled = () => {
    if (!wrapper) {
      return;
    }

    const elem = wrapper.current;

    if (!elem) {
      return;
    }

    setPrevScroll(elem.scrollTop);

    // only when scrolling down
    if (elem.scrollTop <= prevScroll) {
      return;
    }

    const range = 10;
    const diff = elem.scrollHeight - elem.scrollTop;

    if (diff >= elem.clientHeight - range && diff <= elem.clientHeight + range) {
      mediator.publish('ui:main-wrapper:scroll-end');
    }
  };

  useEffect(() => {
    const subscription = mediator.subscribe('ui:main-wrapper:scroll-top', scrollTop);

    scrollTop();

    return () => {
      if (subscription) {
        mediator.remove('ui:main-wrapper:scroll-top', subscription.id);
      }
    };
  }, [scrollTop]);

  return (
    <div
      id="wizard-main-container"
      ref={wrapper}
      onScroll={onScrolled}
      className={`${styles.mainContainer}`}
    >
      <div id="wizard-container" className="container">
        {children}
      </div>
    </div>
  );
};

export default WizardMainContainer;
