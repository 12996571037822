import { useMemo } from 'react';

import LandingPage from 'Components/LandingPage';
import ForgotPasswordPage from 'Components/auth/ForgotPassword';
import LoginPage from 'Components/auth/Login';
import ResetPasswordPage from 'Components/auth/ResetPassword';
import Consents from 'Components/consents/ConsentsScreen';
import Document from 'Components/consents/Document';
import HipaaConsent from 'Components/consents/HipaaConsent';
import RWEConsent from 'Components/consents/RWEConsent';
import VerifyConsents from 'Components/consents/VerifyConsents';
import RegisterPage from 'Components/register/CreateNewUser';
import AccountInformation from 'Components/settings/AccountInformation';
import UpdatePasswordPage from 'Components/settings/UpdatePassword';
import InitializationErrorPage from 'Components/utility/InitializationErrorPage';

const routes: { route: string; component: React.FC<any>; props?: any }[] = [
  { route: 'login', component: LoginPage },
  { route: 'create-account', component: RegisterPage },
  { route: 'forgot-password', component: ForgotPasswordPage },
  { route: 'reset-password', component: ResetPasswordPage },
  { route: 'account-information', component: AccountInformation },
  { route: 'update-password', component: UpdatePasswordPage },
  { route: 'hipaa', component: HipaaConsent },
  { route: 'rwe', component: RWEConsent },
  { route: 'terms-of-use', component: Document, props: { type: 'toulibre' } },
  { route: 'privacy-notice', component: Document, props: { type: 'pnlibre' } },
  { route: 'verify-consents', component: VerifyConsents },
  { route: 'consents', component: Consents },
];

const useRouter = (location: string, queryString: string, initErrorStatus?: number) => {
  const { Component, props } = useMemo(() => {
    if (initErrorStatus) {
      return {
        Component: InitializationErrorPage,
        props: { errorStatus: initErrorStatus, navigationQueryString: queryString },
      };
    }

    const lowerCaseLocation = location.toLowerCase();

    const route = routes.find((r) => lowerCaseLocation.startsWith(`/${r.route.toLowerCase()}`));

    if (route) {
      return { Component: route.component, props: route.props };
    }

    return { Component: LandingPage };
  }, [initErrorStatus, location, queryString]);

  return { Component, props };
};

export default useRouter;
