import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import { RegisterProps } from 'Components/register/CreateNewUser';
import Footer from 'Components/utility/Footer';
import Form from 'Components/utility/Form';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import { Input } from 'Components/utility/Input';
import InputPassword from 'Components/utility/InputPassword';
import Main from 'Components/utility/Main';
import PasswordInputValidator from 'Components/utility/PasswordInputValidator';
import ScrollContainer from 'Components/utility/ScrollContainer';
import Spinner from 'Components/utility/Spinner';
import { NavigationProps } from 'Components/wizard';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';

import { RootState } from 'src/reducers';

const mapStateToProps = ({ config: { validation } }: RootState) => {
  return {
    validation,
    leadingTrailingSpacesIdentity: validation?.identity.find(
      (identity) => identity.data.key === 'leadingTrailingSpaces'
    ),
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & NavigationProps<Register> & RegisterProps;

const RegisterMinorAccountInfo: React.FC<Props> = ({
  back,
  values: currentValues,
  handleSubmitRegister,
  loading,
  validation,
  leadingTrailingSpacesIdentity,
}) => {
  const [meetsPasswordRequirements, setMeetsPasswordRequirements] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoFormMinor.formField.parentEmail.errors.emailAddressValidEmail'
        )
      )
      .required(
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoFormMinor.formField.parentEmail.errors.required'
        )
      ),
    password: leadingTrailingSpacesIdentity
      ? Yup.string().matches(
          new RegExp(leadingTrailingSpacesIdentity.data.regex),
          i18n.t<string>(
            `Login.content.signInForm.formField.password.helpText.${leadingTrailingSpacesIdentity.data.key}`
          )
        )
      : Yup.string(),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.errors.confirmPasswordMustMatch'
        )
      )
      .required(
        i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.errors.required'
        )
      ),
  });

  const onSubmitForm = useCallback(
    async (values: Register, { setSubmitting }: FormikHelpers<Register>) => {
      setSubmitting(true);

      const register: Register = {
        ...currentValues,
        ...values,
        password: values.password.trim(),
      };

      try {
        await handleSubmitRegister(register);
      } catch (err) {
        log.error(err);
      } finally {
        setSubmitting(false);
      }
    },
    [currentValues, handleSubmitRegister]
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    isValid,
    isSubmitting,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      ...currentValues,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmitForm,
    validateOnBlur: true,
  });

  const handleOnBack = useCallback(() => {
    back({ ...values, password: '', confirmPassword: '' });
  }, [back, values]);

  return (
    <Main>
      {loading && <Spinner />}
      <ActionBarHeader
        nativeIDPressable="CreateAccountAccountInfo.action-go-back"
        nativeIDTitle="CreateAccountAccountInfo.actionTitle"
        title={i18n.t<string>('CreateAccountAccountInfo.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <ContentHeader
          nativeIDTitle="CreateAccountAccountInfo.title"
          nativeIDSubtitle="CreateAccountAccountInfo.subtitle"
          nativeIDPressable="CreateAccountAccountInfo.go-back"
          title={i18n.t<string>('CreateAccountAccountInfo.title')}
          subtitle={i18n.t<string>(
            'CreateAccountAccountInfo.content.createAccountAccountInfoFormMinor.primaryText'
          )}
          onBack={handleOnBack}
        />
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoFormMinor.formField.parentEmail.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoFormMinor.formField.parentEmail.label'
            )}
            errorMessage={errors.email}
            isInvalid={errors.email && touched.email ? true : false}
            nativeID="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            keyboardType="email-address"
          />
          <PasswordInputValidator
            placeholder={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.password.label'
            )}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            password={values.password}
            onValidate={setMeetsPasswordRequirements}
            identities={validation?.identity}
            nativeID="password"
          />
          <InputPassword
            placeholder={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountAccountInfo.content.createAccountAccountInfoForm.formField.confirmPassword.label'
            )}
            isInvalid={errors.confirmPassword && touched.confirmPassword ? true : false}
            errorMessage={errors.confirmPassword}
            nativeID="confirmPassword"
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            value={values.confirmPassword}
          />
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'CreateAccountAccountInfo.content.createAccountAccountInfoForm.links.next.label'
        )}
        onButtonSubmit={handleSubmit}
        nativeIDButton="CreateAccountAccountInfo.nextButton"
        isButtonDisabled={
          !values.email ||
          !values.password ||
          !values.confirmPassword ||
          !isValid ||
          isSubmitting ||
          !meetsPasswordRequirements
        }
      />
    </Main>
  );
};

export default connector(RegisterMinorAccountInfo);
