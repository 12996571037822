import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Text } from '@adc-polaris-component-library/component-library';
import { Divider, Flex } from 'native-base';

import { Button } from 'Components/utility/Button';
import Link from 'Components/utility/Link';

import { checkBrowser } from 'Utilities/browser';
import { store } from 'Utilities/store';

import { RootState } from 'src/reducers';

const { isAndroid } = checkBrowser();

type FooterProps = {
  buttonText?: string;
  onButtonSubmit?: () => void;
  nativeIDButton?: string;
  buttonLinkText?: string;
  onButtonLinkClick?: () => void;
  nativeIDButtonLink?: string;
  isButtonDisabled?: boolean;
  linkText?: string;
  customProps?: JSX.Element;
  visible?: boolean;
};

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & FooterProps;

const Footer: React.FC<Props> = ({
  scrollPage,
  buttonText,
  onButtonSubmit,
  nativeIDButton,
  isButtonDisabled,
  linkText,
  onButtonLinkClick,
  nativeIDButtonLink,
  buttonLinkText,
  customProps,
  visible = true,
}) => {
  const footerRef = useRef<HTMLDivElement>();

  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    if (footerRef.current) {
      store.dispatch({ type: 'FOOTER_SIZE', footerSize: footerRef.current.clientHeight });

      // This is a hack to prevent the footer from moving to the top of screen when the keyboard is open on Android.
      if (isAndroid) {
        const sumedges = window.innerWidth + window.innerHeight;

        window.visualViewport?.addEventListener('resize', () => {
          const keyboardDidShow = window.innerWidth + window.innerHeight < sumedges;

          setIsVisible(!keyboardDidShow);
        });
      }
    }
  }, []);

  const hasScrollOrBottomedOut = !scrollPage.isActive || scrollPage.bottomedOut;

  const onButtonClick = useCallback(() => {
    onButtonSubmit && onButtonSubmit();

    store.dispatch({ type: 'SCROLL_RESET' });
  }, [onButtonSubmit]);

  const onButtonLink = useCallback(() => {
    onButtonLinkClick && onButtonLinkClick();

    store.dispatch({ type: 'SCROLL_RESET' });
  }, [onButtonLinkClick]);

  if (!isVisible) {
    return null;
  }

  return (
    <Flex
      ref={footerRef}
      padding={4}
      pb={isAndroid ? 2 : 4}
      alignSelf="flex-end"
      w="100%"
      bgColor={
        !scrollPage.isActive || scrollPage.bottomedOut ? 'transparent' : 'scrollState.container'
      }
      style={{
        shadowOffset: { width: 0, height: hasScrollOrBottomedOut ? 0 : -4 },
        shadowOpacity: hasScrollOrBottomedOut ? 0 : 0.08,
        shadowRadius: hasScrollOrBottomedOut ? 0 : 12,
      }}
    >
      {customProps ? (
        customProps
      ) : (
        <>
          <Button onPress={onButtonClick} nativeID={nativeIDButton} isDisabled={isButtonDisabled}>
            {buttonText}
          </Button>
          {onButtonLinkClick && buttonLinkText && nativeIDButtonLink && (
            <>
              <Divider my="5" bg="neutral.40" />
              <Text
                nativeID="Auth.doNotHaveAccount"
                fontFamily="bodyBaseDefault"
                fontWeight="bodyBaseDefault"
                color="text.80"
                textAlign="center"
                fontSize="l"
              >
                {linkText}
              </Text>
              <Link
                onPress={onButtonLink}
                text={buttonLinkText}
                testIDText={nativeIDButtonLink}
                testIDIcon={nativeIDButtonLink}
              />
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default connector(Footer);
