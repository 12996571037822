import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { SingleButton as Link } from '@adc-polaris-component-library/component-library';
import { AxiosError } from 'axios';
import { Flex, Stack } from 'native-base';

import { ApiStatus, ErrorCode } from 'Enums';

import { useApiError } from 'Hooks';

import { Button } from 'Components/utility/Button';
import Footer from 'Components/utility/Footer';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import Main from 'Components/utility/Main';
import OptInOut from 'Components/utility/OptInOut';
import { RichText } from 'Components/utility/RichText/RichText';
import ScrollContainer from 'Components/utility/ScrollContainer';
import Spinner from 'Components/utility/Spinner';

import { AUTH_SESSION } from 'Reducers/auth/types';
import { RootState } from 'Reducers/index';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { logout } from 'Utilities/session';
import { store } from 'Utilities/store';

import { handleConsent } from 'Services/consent';

const mapStateToProps = ({
  env: { osType, osVersion, app, appVersion },
  auth,
  nav: { query },
}: RootState) => {
  return {
    osType,
    osVersion,
    app,
    appVersion,
    auth,
    isAccountProfile: !!query.accountProfile,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const HipaaConsent: React.FC<Props> = ({
  osType,
  osVersion,
  isAccountProfile,
  app,
  appVersion,
  auth,
}) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal, showNetworkErrorModal } = useApiError();

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.UNAUTHORIZED:
          logout();
          break;
        default:
          error.code === ErrorCode.NETWORK_ERROR ? showNetworkErrorModal() : showApiErrorModal();
      }
    },
    [showApiErrorModal, showNetworkErrorModal]
  );

  const handleConsentWrapper = useCallback(
    async (action: string) => {
      return new Promise<AuthSession>((resolve, reject) => {
        handleConsent(auth.account_id, action, osType, osVersion, app, appVersion, 'hipaa')
          .then((resp) => {
            const updatedAuthSession: AuthSession = {
              ...auth,
              include: {
                patient: {
                  ...auth.include.patient,
                  consents: { ...auth.include.patient.consents, hipaa: { ...resp } },
                },
              },
            };

            store.dispatch({ type: AUTH_SESSION, authSession: updatedAuthSession });

            notifyApp('adc-webview:consents', {
              id: 'hipaa',
              action,
              location: isAccountProfile ? 'profile' : 'registration',
            });

            resolve(updatedAuthSession);
          })
          .catch((error: AxiosError<ApiErrorData>) => {
            handleApiRequestError(error);
            reject(error);
          });
      });
    },
    [app, appVersion, auth, handleApiRequestError, isAccountProfile, osType, osVersion]
  );

  const handleSubmitHipaaAction = useCallback(
    async (action: string) => {
      try {
        setLoading(true);

        const updatedAuthSession = await handleConsentWrapper(action);

        if (!auth.include.patient.consents?.realWorldEvidence?.at) {
          mediator.publish('router:navigate', '/rwe');
          return;
        }

        notifyApp<AuthSession>('adc-webview:signin', updatedAuthSession);

        // This timeout is added to give the app time to redirect the user back.
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      } catch (err) {
        setLoading(false);
      }
    },
    [auth, handleConsentWrapper]
  );

  return (
    <Main>
      {loading && <Spinner />}
      <ActionBarHeader
        nativeIDTitle={isAccountProfile ? 'Hipaa.title' : 'Hipaa.actionTitle'}
        nativeIDPressable="Hipaa.close"
        title={i18n.t<string>('Hipaa.title')}
        noActionButton={!isAccountProfile}
        defaultActionButton="close"
        fixed={isAccountProfile}
      />
      <ScrollContainer>
        <ContentHeader
          nativeIDTitle="Hipaa.title"
          title={i18n.t<string>('Hipaa.title')}
          visible={!isAccountProfile}
          noActionBar
        />
        <Flex
          paddingX={4}
          paddingBottom={8}
          overflow="scroll"
          overflowY="auto"
          style={{
            marginTop: isAccountProfile ? 80 : 16,
          }}
        >
          <RichText text={i18n.t<string>('Hipaa.content.hipaaRichText.bodyText')} />
        </Flex>
      </ScrollContainer>

      <Footer
        visible={!isAccountProfile}
        customProps={
          <Flex alignSelf="flex-end" w="100%">
            <Button onPress={() => handleSubmitHipaaAction('accept')} nativeID="Hipaa.accept">
              {i18n.t<string>('Global.microcopy.common.accept')}
            </Button>
            <Stack mt={4}>
              <Link
                variant="textPrimary"
                onPress={() => handleSubmitHipaaAction('decline')}
                text={i18n.t<string>('Global.microcopy.common.decline')}
                testIDText="Hipaa.decline"
              />
            </Stack>
          </Flex>
        }
      />
      <OptInOut
        isVisible={isAccountProfile}
        optionStrings={{
          titleTag: 'Global.microcopy.common.status',
          positiveTag: 'Global.microcopy.common.accepted',
          negativeTag: 'Global.microcopy.common.declined',
          positiveOption: 'Global.microcopy.common.accept',
          negativeOption: 'Global.microcopy.common.decline',
        }}
        initialState={!!auth.include.patient?.consents?.hipaa?.accepted ? 'accept' : 'decline'}
        handleConsent={handleConsentWrapper}
      />
    </Main>
  );
};

export default connector(HipaaConsent);
