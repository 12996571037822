import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import { VStack } from 'native-base';
import * as Yup from 'yup';

import { ApiStatus, ErrorCode } from 'Enums';

import { useAccount, useApiError, useAuthError } from 'Hooks';

import Banner from 'Components/utility/Banner';
import Footer from 'Components/utility/Footer';
import Form from 'Components/utility/Form';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import InputPassword from 'Components/utility/InputPassword';
import Link from 'Components/utility/Link';
import Main from 'Components/utility/Main';
import PasswordInputValidator from 'Components/utility/PasswordInputValidator';
import ScrollContainer from 'Components/utility/ScrollContainer';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

import { updatePassword } from 'Services/patient';

import { RootState } from 'src/reducers';

const mapStateToProps = ({
  config: { validation },
  auth,
  env: { deviceId, osType, osVersion, app, appVersion },
}: RootState) => {
  return {
    validation,
    email: auth.include.patient.email,
    accountId: auth.account_id,
    deviceId,
    osType,
    osVersion,
    app,
    appVersion,
    leadingTrailingSpacesIdentity: validation?.identity.find(
      (identity) => identity.data.key === 'leadingTrailingSpaces'
    ),
  };
};

interface MyFormValues {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const initialValuesFormik: MyFormValues = {
  currentPassword: '',
  newPassword: '',
  repeatNewPassword: '',
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const UpdatePassword: React.FC<Props> = ({
  validation,
  email,
  accountId,
  osType,
  osVersion,
  app,
  appVersion,
  leadingTrailingSpacesIdentity,
}) => {
  const [meetsPasswordRequirements, setMeetsPasswordRequirements] = useState(false);

  const { showPasswordValidationError } = useAccount();

  const { showApiErrorModal, showNetworkErrorModal } = useApiError();

  const {
    handleLoginError,
    handleLockoutError,
    showHcpError,
    isLocked,
    lockoutMessage,
    lockoutSubmessage,
  } = useAuthError('changepassword');

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(
        i18n.t<string>(
          'ChangePassword.content.changePasswordAccountInfoForm.formField.currentPassword.errors.required'
        )
      )
      .trim(),
    newPassword: leadingTrailingSpacesIdentity
      ? Yup.string().matches(
          new RegExp(leadingTrailingSpacesIdentity.data.regex),
          i18n.t<string>(
            `Login.content.signInForm.formField.password.helpText.${leadingTrailingSpacesIdentity.data.key}`
          )
        )
      : Yup.string(),
    repeatNewPassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword')],
        i18n.t<string>(
          'ChangePassword.content.changePasswordAccountInfoForm.formField.repeatNewPassword.errors.passwordMustMatch'
        )
      )
      .required(
        i18n.t<string>(
          'ChangePassword.content.changePasswordAccountInfoForm.formField.currentPassword.errors.required'
        )
      ),
  });

  const handleClickForgotPass = () => {
    mediator.publish('router:navigate', '/forgot-password');
  };

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    setTouched,
    isValid,
    isSubmitting,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: initialValuesFormik,
    onSubmit: onSubmitForm,
    validationSchema: validationSchema,
    validateOnBlur: true,
  });

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>, resetForm: () => void) => {
      switch (error.status) {
        case ApiStatus.FORBIDDEN: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showHcpError();
          }

          break;
        }

        case ApiStatus.UNAUTHORIZED:
          handleLoginError(error, resetForm, () => setFieldValue('currentPassword', ''));
          break;

        case ApiStatus.BAD_REQUEST: {
          const errors = error.response?.data.errors;

          setFieldValue('newPassword', '');
          setFieldValue('repeatNewPassword', '');

          setTouched({});

          showPasswordValidationError({ validation }, errors);
          break;
        }

        case ApiStatus.TOO_MANY_REQUESTS: {
          handleLockoutError(error);
          resetForm();
          break;
        }

        default:
          error.code === ErrorCode.NETWORK_ERROR ? showNetworkErrorModal() : showApiErrorModal();
      }
    },
    [
      handleLockoutError,
      handleLoginError,
      setFieldValue,
      setTouched,
      showApiErrorModal,
      showHcpError,
      showNetworkErrorModal,
      showPasswordValidationError,
      validation,
    ]
  );

  function onSubmitForm(
    data: MyFormValues,
    { setSubmitting, resetForm }: FormikHelpers<MyFormValues>
  ) {
    updatePassword(
      {
        userID: accountId,
        emailConfirmation: email,
        newPassword: data.newPassword,
        currentPassword: data.currentPassword,
      },
      osType,
      osVersion,
      app,
      appVersion
    )
      .then(() => {
        notifyApp('adc-webview:update-password');
      })
      .catch((error: AxiosError<ApiErrorData>) => {
        handleApiRequestError(error, resetForm);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Main>
      <ActionBarHeader
        nativeIDTitle="ChangePassword.title"
        nativeIDPressable="ChangePassword.go-back"
        title={i18n.t<string>('ChangePassword.title')}
        fixed
      />
      <ScrollContainer>
        <VStack style={{ marginTop: 50 }}>
          <Banner message={lockoutMessage} submessage={lockoutSubmessage} mt={3} />
          <Form onSubmit={handleSubmit}>
            <InputPassword
              label={i18n.t<string>(
                'ChangePassword.content.changePasswordAccountInfoForm.formField.currentPassword.label'
              )}
              placeholder={i18n.t<string>(
                'ChangePassword.content.changePasswordAccountInfoForm.formField.currentPassword.placeholder'
              )}
              isInvalid={errors.currentPassword && touched.currentPassword ? true : false}
              errorMessage={errors.currentPassword}
              nativeID="currentPassword"
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values.currentPassword}
              isDisabled={isLocked}
            />
            <Link
              testIDText="ChangePassword.forgotPassword"
              onPress={handleClickForgotPass}
              onPressIn={handleClickForgotPass}
              text={i18n.t<string>(
                'ChangePassword.content.changePasswordAccountInfoForm.links.forgotPasswordLink.label'
              )}
            />
            <PasswordInputValidator
              placeholder={i18n.t<string>(
                'ChangePassword.content.changePasswordAccountInfoForm.formField.newPassword.placeholder'
              )}
              label={i18n.t<string>(
                'ChangePassword.content.changePasswordAccountInfoForm.formField.newPassword.label'
              )}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              password={values.newPassword}
              onValidate={setMeetsPasswordRequirements}
              identities={validation?.identity}
              nativeID="newPassword"
              isDisabled={isLocked}
            />
            <InputPassword
              mt="1"
              label={i18n.t<string>(
                'ChangePassword.content.changePasswordAccountInfoForm.formField.repeatNewPassword.label'
              )}
              placeholder={i18n.t<string>(
                'ChangePassword.content.changePasswordAccountInfoForm.formField.repeatNewPassword.placeholder'
              )}
              isInvalid={errors.repeatNewPassword && touched.repeatNewPassword ? true : false}
              errorMessage={errors.repeatNewPassword}
              nativeID="repeatNewPassword"
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values.repeatNewPassword}
              isDisabled={isLocked}
            />
          </Form>
        </VStack>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Global.microcopy.common.save')}
        onButtonSubmit={handleSubmit}
        nativeIDButton="ChangePassword.save-button"
        isButtonDisabled={
          !values.currentPassword ||
          !values.newPassword ||
          !values.repeatNewPassword ||
          !isValid ||
          isSubmitting ||
          !meetsPasswordRequirements
        }
      />
    </Main>
  );
};

export default connector(UpdatePassword);
