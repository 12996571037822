import React, { useCallback, useMemo, useState } from 'react';

import { Modal } from '@adc-polaris-component-library/component-library';

import { ApiStatus } from 'Enums';

import Main from 'Components/utility/Main';
import Spinner from 'Components/utility/Spinner';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';

type Props = {
  errorStatus?: number;
  navigationQueryString?: string;
};

type Error = {
  title: string;
  message: string;
  action?: () => void;
};

const InitializationErrorPage: React.FC<Props> = ({ errorStatus }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const error: Error = useMemo(() => {
    switch (errorStatus) {
      case ApiStatus.INTERNAL_SERVER_ERROR:
        return {
          title: 'Global.modals.internetConnectionError.title',
          message: 'Global.modals.internetConnectionError.body',
        };
      case ApiStatus.UNAUTHORIZED:
        return {
          title: 'Global.modals.featureNotAvailable.title',
          message: 'Global.modals.featureNotAvailable.body',
        };
      default:
        return {
          title: 'Global.modals.internetConnectionError.title',
          message: 'Global.modals.errorCommunicatingServer.body',
        };
    }
  }, [errorStatus]);

  const onClose = useCallback(() => {
    setIsOpen(false);

    setLoading(true);

    notifyApp('adc-webview:navigate-back-app');

    // Give the app some time to redirect the user back.
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <Main>
      {loading && <Spinner />}
      <Modal isOpen={isOpen}>
        <Modal.Header testID={error.title}>{i18n.t<string>(error.title)}</Modal.Header>
        <Modal.Body>
          <Modal.BodyText testID={error.message} nativeID={error.message}>
            {i18n.t<string>(error.message)}
          </Modal.BodyText>
        </Modal.Body>
        <Modal.ButtonContainer>
          <Modal.Button testID="Global.microcopy.common.okCap" onPress={onClose}>
            {i18n.t<string>('Global.microcopy.common.okCap')}
          </Modal.Button>
        </Modal.ButtonContainer>
      </Modal>
    </Main>
  );
};

export default InitializationErrorPage;
