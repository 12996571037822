import styled, { css } from 'styled-components';

type ContainerProps = {
  overlapped: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${(props) => css`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-top: 12px;
    padding-right: 16px;
    padding-left: 16px;
    backdrop-filter: blur(15px);
    box-shadow: ${props.overlapped ? '0px 4px 12px rgba(0, 0, 0, 0.08)' : '0px'};
  `}
`;
