import React, { useState } from 'react';

import {
  BottomSheetModal,
  DefaultTag,
  Text,
} from '@adc-polaris-component-library/component-library';
import { Box, Flex, HStack, Pressable } from 'native-base';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';

import { IconBaseComponent } from '../IconBaseComponent';

type OptStateType = null | 'accept' | 'decline';

type OptInBoxProps = {
  isSelected: boolean | null;
  textContent: string;
};

type OptInOutModalProps = {
  handleUpdate: (state: OptStateType) => Promise<void>;
  previousState: OptStateType;
  isOpen: boolean;
  setIsOpen(bool: boolean): void;
  positiveOption: string;
  negativeOption: string;
};

interface IOptInOut {
  handleConsent: (prop: string) => Promise<AuthSession>;
  initialState: OptStateType;
  optionStrings: {
    titleTag: string;
    positiveTag: string;
    negativeTag: string;
    positiveOption: string;
    negativeOption: string;
  };
  isVisible: boolean;
}

const OptInBox = ({ isSelected, textContent }: OptInBoxProps) => {
  return (
    <Box position="relative" display="flex" alignItems="center" justifyContent="center">
      <Box
        w="100%"
        borderRadius="10px"
        h="100%"
        bg={isSelected ? 'interactive.primary.focus.strokeOpacity' : 'transparent'}
        px="0"
        py="1"
      >
        <Box
          py="2"
          px="16px"
          outlineColor=""
          borderRadius="10px"
          bg="neutral.container"
          borderStyle="solid"
          borderColor={
            isSelected ? 'interactive.primary.focus.default' : 'inputField.background.defaultStroke'
          }
          borderWidth="2"
          display="flex"
          alignItems="center"
          flexDir="row"
        >
          <IconBaseComponent
            nativeID={`${
              isSelected
                ? 'Global.microcopy.common.checkedOptInOptOutRadioButton'
                : 'Global.microcopy.common.notCheckedOptInOptOutRadioButton'
            }`}
            alt={isSelected ? 'checked icon' : 'not checked icon'}
            iconImage={i18n.t<string>(
              `${
                isSelected
                  ? 'Global.microcopy.common.checkedOptInOptOutRadioButton'
                  : 'Global.microcopy.common.notCheckedOptInOptOutRadioButton'
              }`
            )}
            size={6.5}
            width="100%"
            background="red"
            height="100%"
            tintColor="interactive.primary.focus.default"
          />

          <Text
            fontFamily={isSelected ? 'typography.bodyBaseMedium' : 'typography.bodyBaseDefault'}
            fontToken={isSelected ? 'bodyBaseMedium' : 'bodyBaseDefault'}
            color="text.100"
            ml="2"
            mt="1"
          >
            {textContent}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

function OptInOutModal({
  previousState,
  negativeOption,
  positiveOption,
  isOpen,
  setIsOpen,
  handleUpdate,
}: OptInOutModalProps) {
  const [optState, setOptState] = useState<OptStateType>(previousState);
  const isUpdatedDisabled = previousState === optState;
  const isAccepted = optState === 'accept';
  const isDeclined = optState === 'decline';

  return (
    <BottomSheetModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      closeButtonAccessibilityLabel="Cancel"
      headerTitle={i18n.t<string>('Global.microcopy.common.status')}
      nativeID="Global.microcopy.common.status"
      titleColorToken="text.title"
      leftActionButton={
        <Pressable nativeID="Global.microcopy.common.cancel" onPress={() => setIsOpen(false)}>
          <Text fontToken={'bodyBaseDefault'} color="interactive.text.active">
            {i18n.t<string>('Global.microcopy.common.cancel')}
          </Text>
        </Pressable>
      }
      rightActionButton={
        <Pressable nativeID="Global.microcopy.common.update" onPress={() => handleUpdate(optState)}>
          <Text
            fontFamily={'typography.bodyBaseMedium'}
            fontToken={'bodyBaseMedium'}
            color={isUpdatedDisabled ? 'interactive.text.disabled' : 'interactive.text.active'}
          >
            {i18n.t<string>('Global.microcopy.common.update')}
          </Text>
        </Pressable>
      }
    >
      <Pressable nativeID={positiveOption} onPress={() => setOptState('accept')} mt="4">
        <OptInBox isSelected={isAccepted} textContent={i18n.t<string>(positiveOption)} />
      </Pressable>

      <Pressable nativeID={negativeOption} onPress={() => setOptState('decline')} mt="3">
        <OptInBox isSelected={isDeclined} textContent={i18n.t<string>(negativeOption)} />
      </Pressable>
    </BottomSheetModal>
  );
}

export default function OptInOut({
  handleConsent,
  initialState,
  optionStrings,
  isVisible,
}: IOptInOut) {
  const [optState, setOptState] = useState<OptStateType>(initialState);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleUpdate = async (state: OptStateType) => {
    if (optState === state) {
      return;
    }

    try {
      await handleConsent(state as string);
      setOptState(state);
      setIsOpen(false);
    } catch (error) {
      log.error(error);
    }
  };

  const isAccepted = optState === 'accept';
  const { negativeOption, negativeTag, positiveOption, positiveTag, titleTag } = optionStrings;

  if (!isVisible) {
    return null;
  }

  return (
    <Flex bg="neutral.container" flexDir="row" justify="space-between" align="center" p={4} pb={6}>
      <HStack display="flex" flexDir="row" alignItems="center" space={1}>
        <Text
          fontFamily={'typography.bodyBaseMedium'}
          fontToken={'bodyBaseMedium'}
          color="text.80"
          nativeID={titleTag}
        >
          {i18n.t<string>(titleTag)}
        </Text>
        <DefaultTag
          testID={isAccepted ? positiveTag : negativeTag}
          tagText={isAccepted ? i18n.t<string>(positiveTag) : i18n.t<string>(negativeTag)}
          variant={isAccepted ? 'success' : 'primary'}
        />
      </HStack>
      <Pressable nativeID="Global.microcopy.common.update" onPress={() => setIsOpen(true)}>
        <Text
          fontFamily={'typography.bodyBaseMedium'}
          fontToken={'bodyBaseMedium'}
          color="interactive.text.active"
        >
          {i18n.t<string>('Global.microcopy.common.update')}
        </Text>
      </Pressable>
      <OptInOutModal
        negativeOption={negativeOption}
        positiveOption={positiveOption}
        handleUpdate={handleUpdate}
        previousState={optState}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Flex>
  );
}
