import React, { memo, useCallback } from 'react';
import Animated, {
  Extrapolation,
  interpolate,
  interpolateColor,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { ConnectedProps, connect } from 'react-redux';

import { Heading, Stack, useToken } from 'native-base';

import ImageButton from 'Components/utility/ImageButton';

import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

import { RootState } from 'src/reducers';

import { Container } from './styles';

type HeaderProps = {
  title?: string;
  nativeIDPressable?: string;
  nativeIDTitle?: string;
  noActionButton?: boolean;
  fixed?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  defaultActionButton?: 'close' | 'back';
};

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & HeaderProps;

const ActionBarHeader: React.FC<Props> = ({
  scrollPage,
  nativeIDPressable,
  nativeIDTitle,
  title,
  onBack,
  onClose,
  noActionButton,
  fixed = false,
  defaultActionButton = 'back',
}) => {
  const [bgScrollState, bgDefault] = useToken('colors', [
    'scrollState.container',
    'neutral.background',
  ]);

  const animatedStyles = useAnimatedStyle(() => {
    if (fixed) {
      const backgroundColor = interpolateColor(
        scrollPage.scrollValue,
        [0, 10],
        [bgDefault, bgScrollState]
      );

      return {
        backgroundColor,
      };
    }

    if (!scrollPage.isActive) {
      return {
        opacity: 0,
      };
    }

    const opacity = interpolate(scrollPage.scrollValue, [0, 20], [0, 1], Extrapolation.CLAMP);

    return {
      opacity,
      backgroundColor: bgScrollState,
    };
  });

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          right: 0,
          paddingBottom: 5,
        },
        animatedStyles,
      ]}
    >
      <Container overlapped={scrollPage.scrollValue > 0}>
        <ActionButton
          onClose={onClose}
          onBack={onBack}
          defaultActionButton={defaultActionButton}
          nativeIDPressable={nativeIDPressable}
          noActionButton={noActionButton}
        />
        <Stack
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Heading
            nativeID={nativeIDTitle}
            fontWeight="titleBaseStrong"
            fontFamily="titleBaseStrong"
            fontSize="titleBaseStrong"
            lineHeight="titleBaseStrong"
            color="text.title"
            textAlign="center"
            maxWidth="70%"
          >
            {title}
          </Heading>
        </Stack>
      </Container>
    </Animated.View>
  );
};

const ActionButton: React.FC<HeaderProps> = memo(
  ({ noActionButton, onClose, onBack, nativeIDPressable, defaultActionButton }) => {
    const handleClickBack = useCallback(() => {
      onBack ? onBack() : mediator.publish('router:back');
      store.dispatch({ type: 'SCROLL_RESET' });
    }, [onBack]);

    const handleClickClose = useCallback(() => {
      onClose ? onClose() : mediator.publish('router:back');

      store.dispatch({ type: 'SCROLL_RESET' });
    }, [onClose]);

    if (noActionButton) {
      return null;
    }

    if (defaultActionButton === 'close') {
      return (
        <ImageButton
          className="close-btn"
          onPress={handleClickClose}
          nativeID={nativeIDPressable}
          imageUriKey="Global.microcopy.common.iconClose"
          pressableProps={{
            justifyContent: 'center',
            width: 7,
            height: 7,
            position: 'absolute',
            zIndex: 1,
          }}
          imageProps={{
            marginRight: 5,
            width: 7,
            height: 7,
          }}
        />
      );
    }

    return (
      <ImageButton
        className="back-btn"
        onPress={handleClickBack}
        nativeID={nativeIDPressable}
        imageUriKey="Global.microcopy.common.iconBackArrow"
        pressableProps={{
          justifyContent: 'center',
          width: 7,
          height: 7,
          position: 'absolute',
          zIndex: 1,
        }}
        imageProps={{
          alt: 'an image of a arrow pointing to left, used to go back to previous screen',
          size: 2,
          width: 5,
          height: 4,
        }}
      />
    );
  }
);

ActionButton.displayName = 'ActionButton';

export default connector(ActionBarHeader);
