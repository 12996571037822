import React, { memo, useEffect, useRef, useState } from 'react';

import { useToken } from 'native-base';
import { Spinner as NBSpinner } from 'native-base';

import i18n from 'Utilities/i18n';

import { Container } from './styles';
import useDatePicker from './useDatePicker';

type Props = {
  onSave: (dateString: string, resetToDate: (date: string) => void) => void;
  onCancel?: () => void;
  isVisible: boolean;
  title: string;
  initialDate?: string;
};

const addPxInFontSize = (str: string) => str + 'px';

const DatePicker: React.FC<Props> = ({ onSave, onCancel, isVisible, title, initialDate }) => {
  const [isDelayedVisible, setIsDelayedVisible] = useState(false);

  const elemRefYear = useRef<HTMLDivElement>(null);
  const elemRefMonth = useRef<HTMLDivElement>(null);
  const elemRefDay = useRef<HTMLDivElement>(null);
  const [textActive, textTitle, neutralBackground, neutral50, text100] = useToken('colors', [
    'interactive.text.active',
    'text.title',
    'neutral.container',
    'neutral.50',
    'text.100',
  ]);
  const [fontSizeXL, fontSizeBodyBaseDefault] = useToken('fontSizes', ['xl', 'bodyBaseDefault']);

  const [fontWeightBold, fontWeightRegular, fontWeightSemiBold] = useToken('fonts', [
    'titleBaseStrong',
    'bodyBaseDefault',
    'bodyBaseMedium',
  ]);

  const { dateString, elems, config, selectInitialDate, loading } = useDatePicker(
    elemRefYear,
    elemRefMonth,
    elemRefDay,
    isVisible,
    initialDate
  );

  const resetToDate = (date: string) => {
    selectInitialDate(elems, config, date);
  };

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsDelayedVisible(true);
      }, 200);

      return () => clearTimeout(timer);
    } else {
      setIsDelayedVisible(false);
    }
  }, [isVisible]);

  return (
    <Container
      textActive={textActive}
      textTitle={textTitle}
      backgroundColor={neutralBackground}
      neutral50={neutral50}
      text100={text100}
      fontSizeXL={addPxInFontSize(fontSizeXL)}
      fontSizeBodyBaseDefault={addPxInFontSize(fontSizeBodyBaseDefault)}
      fontWeightBold={fontWeightBold}
      fontWeightRegular={fontWeightRegular}
      fontWeightSemiBold={fontWeightSemiBold}
    >
      <div
        className="date-selector-container"
        style={{
          left: isDelayedVisible ? 0 : -window.screen.width,
        }}
      >
        {loading && (
          <div className="spinner">
            <NBSpinner color="interactive.primary.active.default" size={40} />
          </div>
        )}
        <div className="date-selector-header" style={{ opacity: loading ? 0 : 1 }}>
          <button id="DatePicker.btnCancel" className="btn-cancel" onClick={onCancel}>
            {i18n.t<string>('Global.microcopy.common.cancel')}
          </button>
          <h1 id="DatePicker.title" className="title">
            {title}
          </h1>
          <button
            id="DatePicker.btnSave"
            className="btn-save"
            onClick={() => {
              if (dateString) {
                onSave(dateString, resetToDate);
              }
            }}
          >
            {i18n.t<string>('Global.microcopy.common.save')}
          </button>
        </div>
        <div id="DatePicker.dateSelector" className="date-selector">
          <div id="DatePicker.selector" className="selector" />
          <div className="month" id="DatePicker.month" ref={elemRefMonth} />
          <div className="day" id="DatePicker.day" ref={elemRefDay} />
          <div className="year" id="DatePicker.year" ref={elemRefYear} />
        </div>
      </div>
    </Container>
  );
};

export default memo(DatePicker);
