import React, { memo, useCallback, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { Box } from 'native-base';
import * as Yup from 'yup';

import DateInput from 'Components/utility/DateInput';
import DatePicker from 'Components/utility/DatePicker';
import Footer from 'Components/utility/Footer';
import Form from 'Components/utility/Form';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import { Input } from 'Components/utility/Input';
import Main from 'Components/utility/Main';
import ScrollContainer from 'Components/utility/ScrollContainer';
import Spinner from 'Components/utility/Spinner';

import { SHOW_MODAL } from 'Reducers/modal/types';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';
import { store } from 'Utilities/store';

import { EntryItem } from 'src/hooks/useAccount';

type Props = {
  patient: Patient;
  firstNameLimit?: number;
  lastNameLimit?: number;
  adultAges: {
    [key: string]: number;
  };
  loading: boolean;
  onSave: (patient: Patient) => void;
  onBack: (initialValues: Patient, currentValues: Patient) => void;
  hasFormChanged: (initialValues: Patient, currentValues: Patient) => boolean;
  validateEntriesLengthAndDisplayError: (items: EntryItem[]) => boolean;
  validateDOBAndDisplayError: (dateOfBirth: string) => boolean;
  validateIsMinor: (dateOfBirth: string, adultAge: number) => boolean;
};

const AccountInfoAdult: React.FC<Props> = ({
  patient,
  firstNameLimit,
  lastNameLimit,
  adultAges,
  loading,
  onSave,
  onBack,
  hasFormChanged,
  validateDOBAndDisplayError,
  validateEntriesLengthAndDisplayError,
  validateIsMinor,
}) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(
        i18n.t<string>(
          'AccountInformationPersonalInfo.content.accountInformationPersonalInfoFormMinor.formField.firstName.errors.required'
        )
      )
      .trim(),
    lastName: Yup.string()
      .required(
        i18n.t<string>(
          'AccountInformationPersonalInfo.content.accountInformationPersonalInfoFormMinor.formField.lastName.errors.required'
        )
      )
      .trim(),
    dateOfBirth: Yup.string().required(
      i18n.t<string>(
        'AccountInformationPersonalInfo.content.accountInformationPersonalInfoFormMinor.formField.dob.errors.required'
      )
    ),
  });

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const onSubmitForm = useCallback(
    async (values: Patient) => {
      const isValid = validateEntriesLengthAndDisplayError([
        {
          value: values.firstName,
          msgKey:
            'CreateAccountAccountInfo.modals.accountInfoErrors.validationRules.firstNameMaxLength',
          count: firstNameLimit,
        },
        {
          value: values.lastName,
          msgKey:
            'CreateAccountAccountInfo.modals.accountInfoErrors.validationRules.lastNameMaxLength',
          count: lastNameLimit,
        },
      ]);

      if (!isValid) {
        return;
      }

      try {
        onSave(values);
      } catch (err) {
        log.error(err);
      }
    },
    [firstNameLimit, lastNameLimit, onSave, validateEntriesLengthAndDisplayError]
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    isSubmitting,
    errors,
    touched,
    values,
    initialValues,
  } = useFormik({
    initialValues: patient,
    validationSchema: validationSchema,
    onSubmit: onSubmitForm,
    validateOnBlur: true,
  });

  const handleOnBack = useCallback(() => {
    onBack(initialValues, values);
  }, [initialValues, values, onBack]);

  const onClickDateInput = useCallback(() => {
    setIsDatePickerVisible((state) => {
      return !state;
    });
  }, []);

  return (
    <Main>
      {loading && <Spinner />}
      {isDatePickerVisible && (
        <Box
          width="100vw"
          height="100vh"
          backgroundColor="neutral.overlay"
          position="absolute"
          zIndex={1}
        />
      )}
      <ActionBarHeader
        nativeIDTitle="AccountInformationPersonalInfo.title"
        nativeIDPressable="AccountInformationPersonalInfo.go-back"
        title={i18n.t<string>('AccountInformationPersonalInfo.title')}
        onBack={handleOnBack}
        fixed
      />
      <ScrollContainer>
        <Form onSubmit={handleSubmit} style={{ marginTop: 50 }}>
          <Input
            placeholder={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.firstName.placeholder'
            )}
            label={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.firstName.label'
            )}
            nativeID="firstName"
            keyboardType="default"
            errorMessage={errors.firstName}
            isInvalid={errors.firstName && touched.firstName ? true : false}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDatePickerVisible}
          />
          <Input
            placeholder={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.lastName.placeholder'
            )}
            label={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.lastName.label'
            )}
            nativeID="lastName"
            keyboardType="default"
            errorMessage={errors.lastName}
            isInvalid={errors.lastName && touched.lastName ? true : false}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDatePickerVisible}
          />
          <DateInput
            placeholder={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.dob.placeholder'
            )}
            label={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.dob.label'
            )}
            isInvalid={errors.dateOfBirth && touched.dateOfBirth ? true : false}
            errorMessage={errors.dateOfBirth}
            keyboardType="default"
            nativeID="dateOfBirth"
            editable={false}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            value={values.dateOfBirth ? format(parseISO(values.dateOfBirth), 'MM/dd/yyyy') : ''}
            isDisabled={isDatePickerVisible}
            onClick={onClickDateInput}
          />
          <Input
            label={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.country.label'
            )}
            nativeID="country"
            keyboardType="default"
            value={i18n.t<string>(`Global.microcopy.countries.${values.country}`)}
            isDisabled={true}
          />
          <Input
            label={i18n.t<string>(
              'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.emailAddress.label'
            )}
            nativeID="email"
            keyboardType="default"
            value={values.email}
            isDisabled={true}
          />
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.links.saveButton.label'
        )}
        onButtonSubmit={handleSubmit}
        nativeIDButton="AccountInformationPersonalInfo.saveButton"
        isButtonDisabled={
          !values.firstName ||
          !values.lastName ||
          !values.dateOfBirth ||
          !isValid ||
          isSubmitting ||
          !hasFormChanged(initialValues, values)
        }
      />
      <DatePicker
        title={i18n.t<string>(
          'AccountInformationPersonalInfo.content.accountInformationPersonalInfoForm.formField.dob.label'
        )}
        isVisible={isDatePickerVisible}
        initialDate={values.dateOfBirth}
        onCancel={() => {
          setIsDatePickerVisible(false);
        }}
        onSave={(date: string, resetToDate: (date: string) => void) => {
          setIsDatePickerVisible(false);

          const isValidDate = validateDOBAndDisplayError(date);

          if (isValidDate) {
            const isMinor = validateIsMinor(date, adultAges['US']);

            if (isMinor) {
              resetToDate(values.dateOfBirth);

              store.dispatch({
                type: SHOW_MODAL,
                modalProps: {
                  message: {
                    msgKey: 'AccountInformationPersonalInfo.modals.minorDOBError.description',
                  },
                },
              });

              return;
            }

            setFieldValue('dateOfBirth', date);
            return;
          }

          resetToDate(values.dateOfBirth);
        }}
      />
    </Main>
  );
};

export default memo(AccountInfoAdult);
