import React, { useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Heading, IStackProps, Stack } from 'native-base';

import ImageButton from 'Components/utility/ImageButton';

import mediator from 'Utilities/mediator';

import { RootState } from 'src/reducers';

type HeaderProps = IStackProps & {
  title: string;
  subtitle?: string;
  nativeIDTitle?: string;
  nativeIDSubtitle?: string;
  nativeIDPressable?: string;
  noActionBar?: boolean;
  visible?: boolean;
  onBack?: () => void;
};

const mapStateToProps = ({ scrollPage }: RootState) => {
  return {
    scrollPage,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & HeaderProps;

const ContentHeader: React.FC<Props> = ({
  scrollPage,
  title,
  subtitle,
  nativeIDTitle,
  nativeIDSubtitle,
  nativeIDPressable,
  onBack,
  noActionBar,
  textAlign,
  visible = true,
  ...rest
}) => {
  const handleClickBack = useCallback(() => {
    onBack ? onBack() : mediator.publish('router:back');
  }, [onBack]);

  if (!visible) {
    return null;
  }

  return (
    <Stack paddingX={4} {...rest}>
      {!noActionBar && (
        <ImageButton
          className="back-btn"
          nativeID={nativeIDPressable}
          onPress={handleClickBack}
          imageUriKey="Global.microcopy.common.iconBackArrow"
          pressableProps={{
            maxW: 5,
            pt: 6,
            justifyContent: 'flex-start',
          }}
          imageProps={{
            alt: 'an image of a arrow pointing to left, used to go back to previous screen',
            marginRight: 5,
            size: 2,
            width: 5,
            height: 4,
          }}
        />
      )}
      <Heading
        nativeID={nativeIDTitle}
        fontWeight={'headlineBaseStrong'}
        fontFamily={'headlineBaseStrong'}
        fontSize="3xl"
        color="text.title"
        display="flex"
        flexDirection="column"
        textAlign={textAlign}
        mt={4}
      >
        {title}
      </Heading>
      <Heading
        nativeID={nativeIDSubtitle}
        color="text.80"
        fontSize="l"
        lineHeight="3xl"
        fontWeight={'bodyBaseDefault'}
        fontFamily={'bodyBaseDefault'}
        textAlign={textAlign}
        mt={2}
      >
        {subtitle}
      </Heading>
    </Stack>
  );
};

export default connector(ContentHeader);
