import React, { ReactNode, forwardRef, memo, useCallback, useState } from 'react';

import { Input as InputADC } from '@adc-polaris-component-library/component-library';
import { Box, FormControl, IInputProps, VStack, useToken } from 'native-base';

import InputErrorField from '../InputErrorField';

type InputProps = {
  isInvalid?: boolean;
  label: string;
  inputHelperText?: string;
  errorMessage?: string;
  placeholder?: string;
  leftElement?: ReactNode;
};

const _text = {
  fontSize: 'l',
  color: 'text.20',
  fontFamily: 'bodyBaseDefault',
  fontWeight: 'bodyBaseDefault',
};

const _disabled = {
  backgroundColor: 'inputField.background.disabledFill',
  color: 'text.20',
  borderStyle: 'solid',
  borderColor: 'inputField.background.disabledStroke',
  opacity: 1,
};

const _isInvalid = {
  borderColor: 'alert.warning.50.active.default',
  borderWidth: 1,
  borderRadius: '10px',
};

const _focus = {
  borderWidth: 1,
  borderRadius: '10px',
  borderTopColor: 'interactive.primary.focus.default',
  borderLeftColor: 'interactive.primary.focus.default',
  borderRightColor: 'interactive.primary.focus.default',
  borderBottomColor: 'interactive.primary.focus.default',
};

const Input = memo(
  forwardRef(
    (
      {
        isInvalid = false,
        label = '',
        inputHelperText,
        errorMessage,
        placeholder = '',
        type = 'text',
        leftElement,
        nativeID,
        mt,
        mb,
        InputRightElement,
        value,
        onBlur,
        ...rest
      }: IInputProps & InputProps,
      ref
    ) => {
      const [isFocused, setIsFocused] = useState(false);
      const [textDisabled] = useToken('colors', ['interactive.text.disabled']);

      const _onBlur = useCallback(
        (e: any) => {
          setIsFocused(false);
          onBlur && onBlur(e);
        },
        [onBlur]
      );

      const _onFocus = useCallback(() => {
        setIsFocused(true);
      }, []);

      return (
        <VStack mt={mt} mb={mb}>
          <FormControl isInvalid={isInvalid}>
            <FormControl.Label testID={`${nativeID}-label`} _text={_text}>
              {label}
            </FormControl.Label>
            <Box position="relative">
              {isFocused && (
                <Box
                  w="100%"
                  py="13px"
                  px="16px"
                  borderRadius="10px"
                  h="100%"
                  position="absolute"
                  bg="interactive.primary.focus.strokeOpacity"
                />
              )}

              <InputADC
                ref={ref ?? undefined}
                placeholder={placeholder}
                type={type}
                py="13px"
                px="16px"
                h="48px"
                m="3px"
                borderColor="inputField.background.defaultStroke"
                borderStyle="solid"
                borderWidth="1"
                backgroundColor="inputField.background.defaultFill"
                fontSize="l"
                color="text.100"
                placeholderTextColor="inputField.text.emptyState"
                _disabled={{
                  ..._disabled,
                  style: {
                    opacity: 0.5,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    '-webkit-text-fill-color': textDisabled,
                  },
                }}
                _invalid={_isInvalid}
                outlineStyle="none"
                invalidOutlineColor="transparent"
                focusOutlineColor="transparent"
                borderRadius="10px"
                onFocus={_onFocus}
                onBlur={_onBlur}
                fontWeight={value ? 'bodyBaseMedium' : 'bodyBaseDefault'}
                fontFamily={value ? 'bodyBaseMedium' : 'bodyBaseDefault'}
                nativeID={nativeID}
                value={value}
                InputRightElement={InputRightElement}
                _focus={_focus}
                _hover={{
                  borderColor: 'inputField.background.defaultStroke',
                  borderStyle: 'solid',
                  borderWidth: '1',
                }}
                {...rest}
              />
            </Box>

            {isInvalid && errorMessage ? (
              <InputErrorField errorMessage={errorMessage} nativeID={nativeID} />
            ) : (
              <FormControl.HelperText nativeID={`${nativeID}-HelperText`}>
                {inputHelperText}
              </FormControl.HelperText>
            )}
          </FormControl>
        </VStack>
      );
    }
  )
);

Input.displayName = 'Input';

export { Input };
