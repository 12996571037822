import React, { memo } from 'react';

import { Box, IBoxProps, useToken } from 'native-base';

type PropsIconBaseComponent = {
  size?: number;
  nativeID: string;
  iconImage?: string;
  tintColor: string;
  alt: string;
};

const IconBaseComponent = memo(
  ({
    iconImage,
    size = 7,
    alt,
    nativeID,
    tintColor,
    ...rest
  }: PropsIconBaseComponent & IBoxProps) => {
    const [iconColor] = useToken('colors', [tintColor]);

    return (
      // the color prop is used for the automation tests to match with Figma design
      <div id={`${nativeID}-container`} color={iconColor}>
        <Box {...rest} width={size * 4} height={size * 4}>
          <img src={iconImage} alt={alt} width={size * 4} height={size * 4} id={nativeID} />
        </Box>
      </div>
    );
  }
);

IconBaseComponent.displayName = 'IconBaseComponent';

export { IconBaseComponent };
