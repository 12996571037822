import React, { useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import Wizard from 'Components/wizard';
import WizardMainContainer from 'Components/wizard/WizardMainContainer';

import { RootState } from 'src/reducers';

import ResetPasswordFirstStep from './steps/ResetPasswordFirstStep';
import ResetPasswordSecondStep from './steps/ResetPasswordSecondStep';

const mapStateToProps = ({ env: { osType, osVersion, app, appVersion } }: RootState) => {
  return {
    osType,
    osVersion,
    app,
    appVersion,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const ResetPassword: React.FC<Props> = () => {
  const [initialValues] = useState<{ password: string; confirmPassword: string }>({
    password: '',
    confirmPassword: '',
  });

  const makeSteps = useMemo(() => {
    return [
      {
        component: ResetPasswordFirstStep,
        componentName: 'ResetPasswordFirstStep',
      },
      {
        component: ResetPasswordSecondStep,
        componentName: 'ResetPasswordSecondStep',
      },
    ];
  }, []);

  return (
    <React.Fragment>
      <WizardMainContainer>
        <Wizard steps={makeSteps} initialValues={initialValues} />
      </WizardMainContainer>
    </React.Fragment>
  );
};

export default connector(ResetPassword);
