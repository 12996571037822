import React, { useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { AxiosError } from 'axios';
import isEqual from 'lodash-es/isEqual';
import { Button, Divider, Stack } from 'native-base';

import { ApiStatus, ErrorCode } from 'Enums';

import { useAccount, useApiError } from 'Hooks';

import { CLOSE_MODAL, SHOW_MODAL } from 'Reducers/modal/types';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { logout } from 'Utilities/session';
import { store } from 'Utilities/store';

import { updatePatient } from 'Services/patient';

import { RootState } from 'src/reducers';

import AccountInfoAdult from './AccountInfoAdult';
import AccountInfoMinor from './AccountInfoMinor';

const mapStateToProps = ({
  auth,
  config: { validation, adultAges },
  env: { osType, osVersion, app, appVersion },
}: RootState) => {
  return {
    patient: auth.include.patient,
    accountId: auth.account_id,
    firstNameLimit: validation?.libreApi.patient.firstName.stringMaxLength,
    lastNameLimit: validation?.libreApi.patient.lastName.stringMaxLength,
    osType,
    osVersion,
    app,
    appVersion,
    adultAges,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const AccountInformation: React.FC<Props> = ({
  patient,
  accountId,
  adultAges,
  osType,
  osVersion,
  app,
  appVersion,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const { validateIsMinor, validateDOBAndDisplayError, validateEntriesLengthAndDisplayError } =
    useAccount();

  const isMinor = validateIsMinor(patient.dateOfBirth, adultAges['US']);

  const { showApiErrorModal, showNetworkErrorModal } = useApiError();

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      switch (error.status) {
        case ApiStatus.UNAUTHORIZED:
          logout();
          break;
        default:
          error.code === ErrorCode.NETWORK_ERROR ? showNetworkErrorModal() : showApiErrorModal();
      }
    },
    [showApiErrorModal, showNetworkErrorModal]
  );

  const onSave = useCallback(
    (patient: Patient) => {
      updatePatient(accountId, patient, osType, osVersion, app, appVersion)
        .then(() => {
          notifyApp('adc-webview:update-account', {
            id: accountId,
            country: patient.country,
            language: patient.language,
            firstName: patient.firstName,
            lastName: patient.lastName,
            dateOfBirth: patient.dateOfBirth,
            email: patient.email,
            guardianFirstName: patient.guardianFirstName,
            guardianLastName: patient.guardianLastName,
          });

          // This timeout is added to give the app time to redirect the user back.
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        })
        .catch((error) => {
          setLoading(false);
          handleApiRequestError(error);
        });
    },
    [accountId, app, appVersion, handleApiRequestError, osType, osVersion]
  );

  const hasFormChanged = useCallback((initialValues: Patient, currentValues: Patient) => {
    return !isEqual(initialValues, currentValues);
  }, []);

  const showUnsavedChangesModal = () => {
    store.dispatch({
      type: SHOW_MODAL,
      modalProps: {
        title: 'AccountInformationPersonalInfo.modals.unsavedChangesError.title',
        message: {
          msgKey: 'AccountInformationPersonalInfo.modals.unsavedChangesError.description',
        },
        footer: (
          <Stack width="100%">
            <Button
              nativeID={
                'AccountInformationPersonalInfo.modals.unsavedChangesError.discardChangesButton'
              }
              variant="link"
              _text={{
                color: 'alert.danger.50.active.default',
                fontWeight: 'bodyBaseMedium',
                fontFamily: 'bodyBaseMedium',
                textDecoration: 'none',
                fontSize: 'base',
              }}
              onPress={() => mediator.publish('router:back')}
            >
              {i18n.t<string>(
                'AccountInformationPersonalInfo.modals.unsavedChangesError.discardChangesButton'
              )}
            </Button>
            <Divider bg="neutral.40" />
            <Button
              nativeID={
                'AccountInformationPersonalInfo.modals.unsavedChangesError.keepEditingButton'
              }
              variant="link"
              _text={{
                color: 'interactive.text.active',
                fontWeight: 'bodyBaseDefault',
                fontFamily: 'bodyBaseDefault',
                textDecoration: 'none',
                fontSize: 'base',
              }}
              onPress={() => {
                store.dispatch({
                  type: CLOSE_MODAL,
                });
              }}
            >
              {i18n.t<string>(
                'AccountInformationPersonalInfo.modals.unsavedChangesError.keepEditingButton'
              )}
            </Button>
          </Stack>
        ),
      },
    });
  };

  const onBack = useCallback(
    (initialValues: Patient, currentValues: Patient) => {
      if (hasFormChanged(initialValues, currentValues)) {
        showUnsavedChangesModal();
        return;
      }

      mediator.publish('router:back');
    },
    [hasFormChanged]
  );

  return (
    <>
      {isMinor ? (
        <AccountInfoMinor
          patient={patient}
          onSave={onSave}
          onBack={onBack}
          hasFormChanged={hasFormChanged}
          validateDOBAndDisplayError={validateDOBAndDisplayError}
          validateEntriesLengthAndDisplayError={validateEntriesLengthAndDisplayError}
          loading={loading}
          {...rest}
        />
      ) : (
        <AccountInfoAdult
          patient={patient}
          onSave={onSave}
          onBack={onBack}
          hasFormChanged={hasFormChanged}
          loading={loading}
          adultAges={adultAges}
          validateDOBAndDisplayError={validateDOBAndDisplayError}
          validateEntriesLengthAndDisplayError={validateEntriesLengthAndDisplayError}
          validateIsMinor={validateIsMinor}
          {...rest}
        />
      )}
    </>
  );
};

export default connector(AccountInformation);
