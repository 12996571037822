import React, { useCallback } from 'react';

import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';

import Footer from 'Components/utility/Footer';
import Form from 'Components/utility/Form';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import { Input } from 'Components/utility/Input';
import Main from 'Components/utility/Main';
import Spinner from 'Components/utility/Spinner';

import i18n from 'Utilities/i18n';
import * as log from 'Utilities/log';

export interface FormValues {
  email: string;
}

type Props = {
  handleSubmitForgotPass(email: string): Promise<void>;
  next: (data: FormValues) => void;
  loading: boolean;
};

const initialValuesFormik: FormValues = { email: '' };

const ForgotPassFirstStep: React.FC<Props> = ({ next, handleSubmitForgotPass, loading }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        i18n.t(
          'ForgotPassword.content.forgotPasswordForm.formField.email.errors.emailAddressValidEmail'
        )
      )
      .required(
        i18n.t('ForgotPassword.content.forgotPasswordForm.formField.email.errors.required')
      ),
  });

  const onSubmitForm = useCallback(
    async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      try {
        await handleSubmitForgotPass(values.email);
        next(values);
      } catch (err) {
        log.error(err);
      } finally {
        setSubmitting(false);
      }
    },
    [handleSubmitForgotPass, next]
  );

  const { handleSubmit, handleChange, handleBlur, isValid, isSubmitting, errors, touched, values } =
    useFormik({
      initialValues: initialValuesFormik,
      validationSchema: validationSchema,
      onSubmit: onSubmitForm,
      validateOnBlur: true,
    });

  return (
    <Main>
      {loading && <Spinner />}
      <ContentHeader
        nativeIDTitle="ForgotPass.forgotPassword"
        nativeIDSubtitle="ForgotPass.enterEmailUsedInThisAccount"
        nativeIDPressable="ForgotPass.step1-go-back"
        title={i18n.t<string>('ForgotPassword.title')}
        subtitle={i18n.t<string>('ForgotPassword.subtitle')}
      />
      <Form onSubmit={handleSubmit}>
        <Input
          placeholder={i18n.t<string>(
            'ForgotPassword.content.forgotPasswordForm.formField.email.placeholder'
          )}
          label={i18n.t<string>('ForgotPassword.content.forgotPasswordForm.formField.email.label')}
          errorMessage={errors.email}
          isInvalid={errors.email && touched.email ? true : false}
          nativeID="email"
          value={values.email}
          mt="4"
          onChange={handleChange}
          onBlur={handleBlur}
          keyboardType="email-address"
        />
      </Form>
      <Footer
        buttonText={i18n.t<string>(
          'ForgotPassword.content.forgotPasswordForm.links.sendToEmail.label'
        )}
        onButtonSubmit={handleSubmit}
        nativeIDButton="ForgotPass.sendLinkToEmail"
        isButtonDisabled={!values.email || !isValid || isSubmitting}
      />
    </Main>
  );
};

export default ForgotPassFirstStep;
