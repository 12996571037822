import React, { useCallback } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { RegisterProps } from 'Components/register/CreateNewUser';
import Footer from 'Components/utility/Footer';
import Form from 'Components/utility/Form';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import { Input } from 'Components/utility/Input';
import Main from 'Components/utility/Main';
import ScrollContainer from 'Components/utility/ScrollContainer';
import { NavigationProps } from 'Components/wizard';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';

type Props = NavigationProps<Register> & RegisterProps;

const RegisterMinorPersonalInfo: React.FC<Props> = ({
  next,
  back,
  validateNameLength,
  values: currentValues,
}) => {
  const validationSchema = Yup.object().shape({
    guardianFirstName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentFirstName.helpText.required'
        )
      )
      .trim(),
    guardianLastName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentLastName.helpText.required'
        )
      )
      .trim(),
  });

  const onSubmitForm = useCallback(
    async (values: Register) => {
      try {
        if (!values.guardianFirstName || !values.guardianLastName) {
          return;
        }

        const isValid = validateNameLength(values.guardianFirstName, values.guardianLastName);

        if (!isValid) {
          return;
        }

        const register: Register = {
          ...currentValues,
          ...values,
        };

        next(register);
      } catch (err) {
        log.error(err);
      }
    },
    [currentValues, next, validateNameLength]
  );

  const { handleSubmit, handleChange, handleBlur, isValid, isSubmitting, errors, touched, values } =
    useFormik({
      initialValues: {
        ...currentValues,
      },
      validationSchema: validationSchema,
      onSubmit: onSubmitForm,
      validateOnBlur: true,
    });

  const handleOnBack = useCallback(() => {
    back(values);
  }, [back, values]);

  return (
    <Main>
      <ActionBarHeader
        nativeIDTitle="CreateAccountPersonalInfo.actionTitle"
        nativeIDPressable="CreateAccountPersonalInfo.action-go-back"
        title={i18n.t<string>('CreateAccountPersonalInfo.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <ContentHeader
          nativeIDTitle="CreateAccountPersonalInfo.title"
          nativeIDPressable="CreateAccountPersonalInfo.go-back"
          nativeIDSubtitle="CreateAccountPersonalInfo.subtitle"
          title={i18n.t<string>('CreateAccountPersonalInfo.title')}
          subtitle={i18n.t<string>(
            'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.primaryText'
          )}
          onBack={handleOnBack}
        />
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentFirstName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentFirstName.label'
            )}
            nativeID="guardianFirstName"
            keyboardType="default"
            errorMessage={errors.guardianFirstName}
            isInvalid={errors.guardianFirstName && touched.guardianFirstName ? true : false}
            value={values.guardianFirstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentLastName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.formField.parentLastName.label'
            )}
            nativeID="guardianLastName"
            keyboardType="default"
            errorMessage={errors.guardianLastName}
            isInvalid={errors.guardianLastName && touched.guardianLastName ? true : false}
            value={values.guardianLastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Text
            nativeID="CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.secondaryText"
            fontFamily="headlineBaseMedium"
            fontWeight="headlineBaseMedium"
            fontSize="l"
            color="text.20"
            pt="5"
            textTransform="uppercase"
          >
            {i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoFormMinor.secondaryText'
            )}
          </Text>
          <Input
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.label'
            )}
            nativeID="firstName"
            keyboardType="default"
            value={values.firstName}
            isDisabled={true}
          />
          <Input
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.label'
            )}
            nativeID="lastName"
            keyboardType="default"
            value={values.lastName}
            isDisabled={true}
          />
          <Input
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
            )}
            nativeID="dateOfBirth"
            keyboardType="default"
            value={values.dateOfBirth ? format(parseISO(values.dateOfBirth), 'MM/dd/yyyy') : ''}
            isDisabled={true}
          />
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.links.next.label'
        )}
        onButtonSubmit={handleSubmit}
        nativeIDButton="CreateAccountPersonalInfo.nextButton"
        isButtonDisabled={
          !values.guardianFirstName || !values.guardianLastName || !isValid || isSubmitting
        }
      />
    </Main>
  );
};

export default RegisterMinorPersonalInfo;
