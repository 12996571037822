import React, { useCallback } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { Box, Image } from 'native-base';

import Footer from 'Components/utility/Footer';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import Main from 'Components/utility/Main';
import ScrollContainer from 'Components/utility/ScrollContainer';
import { NavigationProps } from 'Components/wizard';

import { MINOR_FLOW } from 'Reducers/register/types';

import i18n from 'Utilities/i18n';
import { store } from 'Utilities/store';

type Props = NavigationProps<Register>;

const RegisterMinorIntro: React.FC<Props> = ({ next, values }) => {
  const handleSubmit = useCallback(() => {
    next(values);
  }, [next, values]);

  const handleOnBack = useCallback(() => {
    store.dispatch({ type: MINOR_FLOW, isMinorFlow: false });
  }, []);

  const appName = i18n.t<string>('Global.appNames.libreView');

  return (
    <Main>
      <ActionBarHeader
        nativeIDTitle="CreateAccountIntro.actionTitle"
        nativeIDPressable="CreateAccountIntro.action-go-back"
        title={i18n.t<string>('CreateAccountIntro.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <ContentHeader
          nativeIDTitle="CreateAccountIntro.title"
          nativeIDPressable="CreateAccountIntro.go-back"
          title={i18n.t<string>('CreateAccountIntro.title')}
          onBack={handleOnBack}
        />
        <Box alignItems="center" px={4} mt={1}>
          <Image
            size="container"
            resizeMode="contain"
            width="100vw"
            height="260px"
            nativeID="CreateAccountIntro.image-of-family"
            mx="auto"
            source={{
              uri: i18n.t<string>('CreateAccountIntro.content.createAccountIntro.media.introMinor'),
            }}
            alt="a family with the father hugging the mom and the daughter in the middle of them"
            my="3"
            mb="5"
          />
          <Text
            nativeID="CreateAccountIntro.content.createAccountIntro.primaryText"
            color="text.100"
            fontSize="l"
            fontWeight="bodyLargeMedium"
            fontFamily="bodyLargeMedium"
            lineHeight="2xl"
          >
            {i18n.t<string>('CreateAccountIntro.content.createAccountIntro.primaryText')}
          </Text>
          <Text
            mt={4}
            nativeID="CreateAccountIntro.content.createAccountIntro.bodyText"
            color="text.40"
            fontSize="l"
            fontWeight="bodyBaseDefault"
            fontFamily="bodyBaseDefault"
            lineHeight="3xl"
          >
            {i18n.t<string>('CreateAccountIntro.content.createAccountIntro.bodyText', { appName })}
          </Text>
        </Box>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Global.microcopy.common.continue')}
        onButtonSubmit={handleSubmit}
        nativeIDButton="CreateAccountIntro.continue-button"
        isButtonDisabled={false}
      />
    </Main>
  );
};

export default RegisterMinorIntro;
