import React from 'react';

import { VStack } from 'native-base';

import { Button } from 'Components/utility/Button';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';

const LandingScreen = () => {
  const handleClickSignIn = () => {
    mediator.publish('router:navigate', '/login');
  };

  const handleClickCreateAccount = () => {
    mediator.publish('router:navigate', '/create-account');
  };

  return (
    <VStack p={4} justifyContent="center" bg="neutral.background" height="100vh" space={4}>
      <Button onPress={handleClickSignIn} nativeID="LandingPage.signIn">
        {i18n.t<string>('LandingPage.microcopy.landingPageButtons.signIn')}
      </Button>
      <Button onPress={handleClickCreateAccount} nativeID="LandingPage.createAccount">
        {i18n.t<string>('LandingPage.microcopy.landingPageButtons.createAccount')}
      </Button>
    </VStack>
  );
};

export default LandingScreen;
