import React, { memo } from 'react';

import { SingleButton as ButtonADC } from '@adc-polaris-component-library/component-library';
import { IButtonProps } from 'native-base';

type PropsButton = Omit<IButtonProps, 'isDisabled'> & {
  variant?: 'primary' | 'secondary' | 'danger' | 'textPrimary' | 'textSecondary' | 'tertiary';
  autoWidth?: boolean;
  isDisabled?: boolean;
  onPress: () => void;
  testIDIcon?: string;
  showEditIcon?: boolean;
  showHelpIcon?: boolean;
  leftIcon?: React.ElementType | null;
};

const Button = memo(
  ({ onPress, isDisabled, nativeID, variant = 'primary', children, ...rest }: PropsButton) => {
    return (
      <ButtonADC
        onPress={onPress}
        variant={variant}
        testIDText={isDisabled ? `${nativeID}-text-disabled` : `${nativeID}-text`}
        testIDIcon={isDisabled ? `${nativeID}-icon-disabled` : `${nativeID}-icon`}
        testIDWrapper={isDisabled ? `${nativeID}-disabled` : `${nativeID}`}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        disabled={isDisabled}
        text={`${children}`}
        {...rest}
      />
    );
  }
);

Button.displayName = 'Button';

export { Button };
