import React, { forwardRef, memo, useCallback, useRef, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  TextInputFocusEventData,
} from 'react-native';

import { IInputProps } from 'native-base';

import { EyeIconComponent } from 'Components/utility/EyeIconComponent';
import { Input } from 'Components/utility/Input';

import ImageButton from '../ImageButton';

type InputPasswordProps = {
  isInvalid?: boolean;
  label: string;
  inputHelperText?: string;
  errorMessage?: string;
  placeholder?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onFocus?: () => void;
};

type EyeIconElementProps = {
  handleShowPass: () => void;
  nativeID: string;
  showPassword: boolean;
  hasValue: boolean;
};

const EyeIconElement: React.FC<EyeIconElementProps> = memo(
  ({ handleShowPass, showPassword, nativeID, hasValue }) => {
    const tintColor = hasValue ? 'text.100' : 'inputField.text.emptyState';

    return (
      <ImageButton
        nativeID={`${nativeID}.toggle-visibility`}
        onPress={handleShowPass}
        pressableProps={{
          marginRight: 5,
        }}
        tintColor={tintColor}
      >
        <EyeIconComponent
          nativeID={`${nativeID}.eye-icon`}
          showIcon={showPassword}
          activeIcon={hasValue}
        />
      </ImageButton>
    );
  }
);

EyeIconElement.displayName = 'EyeIconElement';

const InputPassword = forwardRef(
  ({
    isInvalid = false,
    label = '',
    errorMessage,
    placeholder = '',
    nativeID = 'password',
    value,
    onBlur,
    onFocus,
    setFieldValue,
    isDisabled,
    ...rest
  }: IInputProps & InputPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const inputRef = useRef<HTMLInputElement>();

    const handleShowPass = useCallback(() => {
      setShowPassword((oldState) => !oldState);

      inputRef.current?.focus();

      // Move cursor at end of text
      setTimeout(() => {
        const length = inputRef.current?.value.length;

        if (length) {
          inputRef.current?.setSelectionRange(length, length);
        }
      }, 10);
    }, []);

    const handleOnChangePassword = useCallback(
      (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
        const password = e.nativeEvent.text;

        setFieldValue(nativeID, password);
      },
      [nativeID, setFieldValue]
    );

    const handleOnBlur = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        const event = e.nativeEvent as unknown as FocusEvent;
        const relatedTarget = event.relatedTarget as null | HTMLElement;

        if (relatedTarget && relatedTarget.id === `${nativeID}.toggle-visibility`) {
          return;
        }

        onBlur && onBlur(e);
      },
      [nativeID, onBlur]
    );

    return (
      <Input
        ref={inputRef}
        placeholder={placeholder}
        label={label}
        isInvalid={isInvalid}
        type={showPassword ? 'text' : 'password'}
        nativeID={nativeID}
        onChange={handleOnChangePassword}
        onBlur={handleOnBlur}
        onFocus={onFocus}
        value={value}
        errorMessage={errorMessage}
        isDisabled={isDisabled}
        InputRightElement={
          !isDisabled ? (
            <EyeIconElement
              nativeID={nativeID}
              handleShowPass={handleShowPass}
              showPassword={showPassword}
              hasValue={value?.length ? true : false}
            />
          ) : undefined
        }
        {...rest}
      />
    );
  }
);

InputPassword.displayName = 'InputPassword';

export default memo(InputPassword);
