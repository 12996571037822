import React, { memo } from 'react';

import { SingleButton } from '@adc-polaris-component-library/component-library';
import { ButtonProps } from '@adc-polaris-component-library/component-library/lib/molecules/buttons/Props';
import { Stack } from 'native-base';

type LinkProps = Omit<ButtonProps, 'variant'>;

const Link: React.FC<LinkProps> = ({ ...rest }) => {
  return (
    <Stack
      style={{
        maxWidth: '160px',
        alignSelf: 'center',
      }}
    >
      <SingleButton variant="textPrimary" {...rest} />
    </Stack>
  );
};

export default memo(Link);
