import React, { memo } from 'react';

import { Text } from '@adc-polaris-component-library/component-library';
import { Flex, IInputProps } from 'native-base';

import i18n from 'Utilities/i18n';

import { IconBaseComponent } from '../IconBaseComponent';

type InputProps = {
  errorMessage?: string;
};

const InputErrorField: React.FC<Partial<IInputProps & InputProps>> = ({
  nativeID,
  errorMessage,
}) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <Flex flexDir="row" align="center" mt="1" mb="2">
      <IconBaseComponent
        nativeID={`${nativeID}-icon`}
        alt="error"
        iconImage={i18n.t<string>('Global.microcopy.common.IconError')}
        size={5.5}
        ml={1}
        tintColor="alert.warning.50.active.default"
      />
      <Text
        display="flex"
        nativeID={`${nativeID}-errorMessage`}
        color="alert.warning.50.active.default"
        lineHeight="2xl"
        fontSize="s"
        ml={2}
        fontFamily="labelBaseDefault"
        fontWeight="labelBaseDefault"
      >
        {errorMessage}
      </Text>
    </Flex>
  );
};

export default memo(InputErrorField);
