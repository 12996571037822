import React from 'react';

export type Props = React.FormHTMLAttributes<HTMLFormElement>;

type FormProps = React.ComponentPropsWithoutRef<'form'>;

const Form = React.forwardRef<HTMLFormElement, FormProps>((props: Props, ref) => {
  const { children, style } = props;

  return (
    <form
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: 16,
        ...style,
      }}
    >
      {children}
    </form>
  );
});

Form.displayName = 'Form';

export default Form;
