import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { AxiosError } from 'axios';

import { ApiStatus, ErrorCode } from 'Enums';

import { useAccount, useApiError } from 'Hooks';

import Wizard from 'Components/wizard';
import WizardMainContainer from 'Components/wizard/WizardMainContainer';

import { AUTH_SESSION } from 'Reducers/auth/types';
import { REGISTER, REGISTER_CLEAR } from 'Reducers/register/types';

import { notifyApp } from 'Utilities/appCommunicator';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

import { signIn } from 'Services/auth';
import { registerPatient } from 'Services/patient';

import { RootState } from 'src/reducers';

import RegisterAccountInfo from './steps/RegisterAccountInfo';
import RegisterMinorAccountInfo from './steps/RegisterMinorAccountInfo';
import RegisterMinorIntro from './steps/RegisterMinorIntro';
import RegisterMinorPersonalInfo from './steps/RegisterMinorPersonalInfo';
import RegisterPersonalInfo from './steps/RegisterPersonalInfo';

export type RegisterProps = {
  handleSubmitRegister(register: Register): Promise<void>;
  handleClickSignIn(): void;
  validateNameLength(firstName: string, lastName: string): boolean;
  validateIsMinor(dateOfBirth: string, adultAge: number): boolean;
  validateDOBAndDisplayError(dateOfBirth: string): boolean;
  loading: boolean;
};

const mapStateToProps = ({
  nav: { query },
  register,
  env: { deviceId, osType, osVersion, app, appVersion },
  config: { validation, adultAges },
}: RootState) => {
  return {
    query,
    deviceId,
    osType,
    osVersion,
    app,
    appVersion,
    validation,
    adultAges,
    register,
    firstNameLimit: validation?.libreApi.patient.firstName.stringMaxLength,
    lastNameLimit: validation?.libreApi.patient.lastName.stringMaxLength,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const CreateNewUser: React.FC<Props> = ({
  deviceId,
  osType,
  osVersion,
  app,
  appVersion,
  validation,
  register,
  firstNameLimit,
  lastNameLimit,
}) => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal, showNetworkErrorModal } = useApiError();

  const {
    showEmailExistsMessage,
    showExistingProUserError,
    showPasswordValidationError,
    validateEntriesLengthAndDisplayError,
    validateDOBAndDisplayError,
    validateIsMinor,
  } = useAccount();

  const makeSteps = useMemo(() => {
    if (register.isMinor) {
      return [
        { component: RegisterMinorIntro, componentName: 'RegisterMinorIntro' },
        { component: RegisterMinorPersonalInfo, componentName: 'RegisterMinorPersonalInfo' },
        { component: RegisterMinorAccountInfo, componentName: 'RegisterMinorAccountInfo' },
      ];
    }

    return [
      { component: RegisterPersonalInfo, componentName: 'RegisterPersonalInfo' },
      { component: RegisterAccountInfo, componentName: 'RegisterAccountInfo' },
    ];
  }, [register.isMinor]);

  useEffect(() => {
    return () => {
      store.dispatch({ type: REGISTER_CLEAR });
    };
  }, []);

  const handleClickSignIn = useCallback(() => {
    /*
      Need to use the redirect method to replaces the current location in browser history.
      When the user clicks the back button on the login page, it should redirect the user back to the app.
    */
    mediator.publish('router:redirect', '/login');
  }, []);

  const validateNameLength = useCallback(
    (firstName: string, lastName: string) => {
      const isValid = validateEntriesLengthAndDisplayError([
        {
          value: firstName,
          msgKey:
            'CreateAccountAccountInfo.modals.accountInfoErrors.validationRules.firstNameMaxLength',
          count: firstNameLimit,
        },
        {
          value: lastName,
          msgKey:
            'CreateAccountAccountInfo.modals.accountInfoErrors.validationRules.lastNameMaxLength',
          count: lastNameLimit,
        },
      ]);

      return isValid;
    },
    [firstNameLimit, lastNameLimit, validateEntriesLengthAndDisplayError]
  );

  const handleApiRequestError = useCallback(
    (error: AxiosError<ApiErrorData>) => {
      const data = error.response?.data
        ? error.response.data
        : {
            details:
              error.code === ErrorCode.NETWORK_ERROR
                ? ErrorCode.NETWORK_ERROR
                : i18n.t('Global.modals.errorCommunicatingServer.body'),
          };

      notifyApp('adc-webview:create-account', { status: error.status, data });

      switch (error.status) {
        case ApiStatus.CONFLICT: {
          const code = error.response?.data.code;

          if (code === ApiStatus.FORBIDDEN_HCP_ACCOUNT) {
            showExistingProUserError();
            return;
          }

          showEmailExistsMessage(handleClickSignIn);
          break;
        }

        case ApiStatus.BAD_REQUEST: {
          const code = error.response?.data.code;
          const errors = error.response?.data.errors;

          if (code === ApiStatus.API_ERROR) {
            showApiErrorModal();
          } else {
            showPasswordValidationError({ validation }, errors);
          }

          break;
        }

        default:
          error.code === ErrorCode.NETWORK_ERROR ? showNetworkErrorModal() : showApiErrorModal();
      }
    },
    [
      handleClickSignIn,
      showApiErrorModal,
      showEmailExistsMessage,
      showExistingProUserError,
      showNetworkErrorModal,
      showPasswordValidationError,
      validation,
    ]
  );

  const handleSubmitRegister = useCallback(
    (register: Register) => {
      return new Promise<void>((resolve, reject) => {
        setLoading(true);

        store.dispatch({ type: REGISTER, register });

        registerPatient(register, osType, osVersion, app, appVersion)
          .then(async () => {
            const authSession = await signIn(
              register.email,
              register.password,
              osType,
              osVersion,
              app,
              appVersion,
              deviceId
            );

            store.dispatch({ type: AUTH_SESSION, authSession });

            notifyApp('adc-webview:create-account', { accountCreated: true });

            mediator.publish('router:navigate', '/hipaa');

            resolve();
          })
          .catch((error) => {
            setLoading(false);
            handleApiRequestError(error);
            reject(error);
          });
      });
    },
    [osType, osVersion, app, appVersion, deviceId, handleApiRequestError]
  );

  return (
    <React.Fragment>
      <WizardMainContainer>
        <Wizard
          steps={makeSteps}
          handleSubmitRegister={handleSubmitRegister}
          handleClickSignIn={handleClickSignIn}
          validateNameLength={validateNameLength}
          validateIsMinor={validateIsMinor}
          validateDOBAndDisplayError={validateDOBAndDisplayError}
          initialValues={register}
          loading={loading}
        />
      </WizardMainContainer>
    </React.Fragment>
  );
};

export default connector(CreateNewUser);
