import React, { memo } from 'react';

import { Box, Flex, IBoxProps, Text } from 'native-base';

import { IconBaseComponent } from 'Components/utility/IconBaseComponent';

import i18n from 'Utilities/i18n';

type Props = IBoxProps & {
  message?: string;
  submessage?: string;
};

const Banner = ({ message, submessage, ...rest }: Props) => {
  if (!message && !submessage) {
    return null;
  }

  const IconErrorInfoRed = i18n.t('Global.microcopy.common.IconError');

  return (
    <Box
      backgroundColor="neutral.container"
      display="flex"
      flexDirection="row"
      borderStyle="solid"
      borderWidth="1"
      borderColor="neutral.20"
      borderLeftStyle="none"
      borderRightStyle="none"
      p={4}
      mb={3}
      nativeID="Banner.wrapper"
      {...rest}
    >
      <IconBaseComponent
        tintColor="svg.icons.alarmsUnavailable"
        top={0}
        nativeID={`Banner.iconError`}
        alt="error"
        marginRight={3}
        iconImage={IconErrorInfoRed}
      />
      <Flex display="flex" flex={1}>
        <Text
          nativeID="Banner.message"
          color="text.100"
          lineHeight="3xl"
          fontFamily="bodyBaseDefault"
          fontSize="l"
        >
          {message}
        </Text>
        <Text
          nativeID="Banner.message2"
          color="text.100"
          lineHeight="3xl"
          fontFamily="bodyBaseDefault"
          fontWeight="headlineBaseStrong"
          fontSize="l"
        >
          {submessage}
        </Text>
      </Flex>
    </Box>
  );
};

export default memo(Banner);
