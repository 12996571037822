import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import axios from 'axios';
import { useToken } from 'native-base';
import styled, { css } from 'styled-components';

import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import Main from 'Components/utility/Main';
import ScrollContainer from 'Components/utility/ScrollContainer';

import { RootState } from 'Reducers/index';

import { getDocumentPathFromLanguage } from 'Utilities/dataHelpers';
import i18n from 'Utilities/i18n';
import log from 'Utilities/log';

const addPxInFontSize = (str: string) => str + 'px';

const mapStateToProps = ({ config, env: { phoneLanguage }, nav: { query } }: RootState) => {
  return {
    documents: {
      toulibre: config.documents?.toulibre,
      pnlibre: config.documents?.pnlibre,
    },
    phoneLanguage,
    isAccountProfile: query.accountProfile === 'true' ? true : false,
  };
};

const connector = connect(mapStateToProps);

type NavigationProps = {
  type: 'pnlibre' | 'toulibre';
};

type Props = ConnectedProps<typeof connector> & NavigationProps;

type DocumentViewProps = {
  textTitle: string;
  text100: string;
  neutralBackground: string;
  fontWeightBold: string;
  fontSizeBodyBaseDefault: string;
  fontSizeHeadlineBaseStrong: string;
};

const DocumentView = styled.div<DocumentViewProps>`
  ${(props) => css`
    background-color: ${props.neutralBackground};

    padding: 0 18px 0 18px;

    h1 {
      color: ${props.textTitle};
      margin: 0;
      font-size: ${props.fontSizeHeadlineBaseStrong};
      font-family: ${props.fontWeightBold};
    }
    h2 {
      color: ${props.textTitle};
      font-family: ${props.fontWeightBold};
      font-size: ${props.fontSizeBodyBaseDefault};
    }
    h3 {
      color: ${props.textTitle};
    }
    p {
      color: ${props.text100};
      font-size: ${props.fontSizeBodyBaseDefault};
    }
    table {
      color: ${props.text100};
      text-align: left;
      border-collapse: collapse;
    }
    li {
      color: ${props.text100};
      font-size: ${props.fontSizeBodyBaseDefault};
    }
    a:link {
      color: ${props.textTitle};
    }
    a:visited {
      color: ${props.textTitle};
    }
    strong {
      color: ${props.text100};
      font-size: ${props.fontSizeBodyBaseDefault};
    }
    table,
    th,
    td {
      border: 1px solid ${props.text100};
    }
    th,
    td {
      padding: 8px;
    }

    .accordion .item input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    .accordion .item input:target ~ .collapsible,
    .accordion .item input:checked ~ .collapsible {
      display: block;
    }

    .accordion .item input ~ .collapsible {
      display: none;
    }

    .accordion .item h3 {
      cursor: pointer;
    }
  `}
`;

const Document: React.FC<Props> = ({ documents, type, phoneLanguage, isAccountProfile }) => {
  const [documentContentHtml, setDocumentContentHtml] = useState('');

  const [textTitle, neutralBackground, text100] = useToken('colors', [
    'text.title',
    'neutral.background',
    'text.100',
  ]);
  const [fontSizeBodyBaseDefault, fontSizeHeadlineBaseStrong] = useToken('fontSizes', [
    'bodyBaseDefault',
    'headlineBaseStrong',
  ]);
  const [fontWeightBold] = useToken('fontConfig.Inter', ['Bold.normal']);

  useEffect(() => {
    const mountDocument = async () => {
      try {
        const documentPath = getDocumentPathFromLanguage(documents[type], phoneLanguage);

        const { data: documentHtml } = await axios.get<string>(documentPath);

        const bodyElement = /<body.*?>([\s\S]*)<\/body>/.exec(documentHtml);

        if (bodyElement && bodyElement.length) {
          const docBodyElement = bodyElement[0];

          setDocumentContentHtml(docBodyElement.concat('</br>'));
        }
      } catch (error) {
        log.error(error);
      }
    };

    mountDocument();
  }, [documents, phoneLanguage, type]);

  return (
    <Main>
      <ActionBarHeader
        nativeIDPressable={isAccountProfile ? 'Document.close' : 'Document.go-back'}
        nativeIDTitle={isAccountProfile ? 'Document.title' : 'Document.actionTitle'}
        title={
          type === 'toulibre'
            ? i18n.t<string>('Global.microcopy.common.termsOfUse')
            : i18n.t<string>('Global.microcopy.common.privacyNotice')
        }
        defaultActionButton={isAccountProfile ? 'close' : 'back'}
        fixed={isAccountProfile}
      />
      <ScrollContainer>
        <ContentHeader
          visible={!isAccountProfile}
          title={
            type === 'toulibre'
              ? i18n.t<string>('Global.microcopy.common.termsOfUse')
              : i18n.t<string>('Global.microcopy.common.privacyNotice')
          }
          nativeIDPressable="Document.go-back"
          nativeIDTitle="Document.title"
        />
        <DocumentView
          fontSizeBodyBaseDefault={addPxInFontSize(fontSizeBodyBaseDefault)}
          fontSizeHeadlineBaseStrong={addPxInFontSize(fontSizeHeadlineBaseStrong)}
          text100={text100}
          textTitle={textTitle}
          fontWeightBold={fontWeightBold}
          neutralBackground={neutralBackground}
          dangerouslySetInnerHTML={{ __html: documentContentHtml }}
          style={{
            marginTop: isAccountProfile ? 55 : 0,
          }}
        />
      </ScrollContainer>
    </Main>
  );
};

export default connector(Document);
