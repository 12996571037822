import React from 'react';

import { Image } from 'native-base';

import i18n from 'Utilities/i18n';

type PropsEyeIconComponent = {
  showIcon?: boolean;
  activeIcon?: boolean;
  size?: number;
  nativeID: string;
};

const EyeIconComponent = ({ showIcon, activeIcon, size = 7, nativeID }: PropsEyeIconComponent) => {
  if (activeIcon) {
    return (
      <Image
        src={
          showIcon
            ? i18n.t<string>('Global.microcopy.common.showPasswordActive')
            : i18n.t<string>('Global.microcopy.common.hidePasswordActive')
        }
        alt=""
        width={size * 4}
        height={size * 4}
        nativeID={nativeID}
        tintColor={'text.100'}
      />
    );
  }

  return (
    <Image
      src={
        showIcon
          ? i18n.t<string>('Global.microcopy.common.showPasswordInactive')
          : i18n.t<string>('Global.microcopy.common.hidePasswordInactive')
      }
      alt=""
      width={size * 4}
      height={size * 4}
      nativeID={nativeID}
      tintColor={'text.100'}
    />
  );
};

export { EyeIconComponent };
